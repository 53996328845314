/* iCheck plugin Square skin
----------------------------------- */
.icheckbox_square,
.iradio_square {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(/build/images/iCheck/square/square.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square {
  background-position: 0 0;
}
.icheckbox_square.hover {
  background-position: -24px 0;
}
.icheckbox_square.checked {
  background-position: -48px 0;
}
.icheckbox_square.disabled {
  background-position: -72px 0;
  cursor: default;
}
.icheckbox_square.checked.disabled {
  background-position: -96px 0;
}

.iradio_square {
  background-position: -120px 0;
}
.iradio_square.hover {
  background-position: -144px 0;
}
.iradio_square.checked {
  background-position: -168px 0;
}
.iradio_square.disabled {
  background-position: -192px 0;
  cursor: default;
}
.iradio_square.checked.disabled {
  background-position: -216px 0;
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_square,
  .iradio_square {
    background-image: url(square@2x.png);
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px;
  }
}

/* red */
.icheckbox_square-red,
.iradio_square-red {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(/build/images/iCheck/square/red.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square-red {
  background-position: 0 0;
}
.icheckbox_square-red.hover {
  background-position: -24px 0;
}
.icheckbox_square-red.checked {
  background-position: -48px 0;
}
.icheckbox_square-red.disabled {
  background-position: -72px 0;
  cursor: default;
}
.icheckbox_square-red.checked.disabled {
  background-position: -96px 0;
}

.iradio_square-red {
  background-position: -120px 0;
}
.iradio_square-red.hover {
  background-position: -144px 0;
}
.iradio_square-red.checked {
  background-position: -168px 0;
}
.iradio_square-red.disabled {
  background-position: -192px 0;
  cursor: default;
}
.iradio_square-red.checked.disabled {
  background-position: -216px 0;
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_square-red,
  .iradio_square-red {
    background-image: url(red@2x.png);
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px;
  }
}

/* green */
.icheckbox_square-green,
.iradio_square-green {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(/build/images/iCheck/square/green.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square-green {
  background-position: 0 0;
}
.icheckbox_square-green.hover {
  background-position: -24px 0;
}
.icheckbox_square-green.checked {
  background-position: -48px 0;
}
.icheckbox_square-green.disabled {
  background-position: -72px 0;
  cursor: default;
}
.icheckbox_square-green.checked.disabled {
  background-position: -96px 0;
}

.iradio_square-green {
  background-position: -120px 0;
}
.iradio_square-green.hover {
  background-position: -144px 0;
}
.iradio_square-green.checked {
  background-position: -168px 0;
}
.iradio_square-green.disabled {
  background-position: -192px 0;
  cursor: default;
}
.iradio_square-green.checked.disabled {
  background-position: -216px 0;
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_square-green,
  .iradio_square-green {
    background-image: url(green@2x.png);
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px;
  }
}

/* blue */
.icheckbox_square-blue,
.iradio_square-blue {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(/build/images/iCheck/square/blue.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square-blue {
  background-position: 0 0;
}
.icheckbox_square-blue.hover {
  background-position: -24px 0;
}
.icheckbox_square-blue.checked {
  background-position: -48px 0;
}
.icheckbox_square-blue.disabled {
  background-position: -72px 0;
  cursor: default;
}
.icheckbox_square-blue.checked.disabled {
  background-position: -96px 0;
}

.iradio_square-blue {
  background-position: -120px 0;
}
.iradio_square-blue.hover {
  background-position: -144px 0;
}
.iradio_square-blue.checked {
  background-position: -168px 0;
}
.iradio_square-blue.disabled {
  background-position: -192px 0;
  cursor: default;
}
.iradio_square-blue.checked.disabled {
  background-position: -216px 0;
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_square-blue,
  .iradio_square-blue {
    background-image: url(blue@2x.png);
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px;
  }
}

/* aero */
.icheckbox_square-aero,
.iradio_square-aero {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(/build/images/iCheck/square/aero.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square-aero {
  background-position: 0 0;
}
.icheckbox_square-aero.hover {
  background-position: -24px 0;
}
.icheckbox_square-aero.checked {
  background-position: -48px 0;
}
.icheckbox_square-aero.disabled {
  background-position: -72px 0;
  cursor: default;
}
.icheckbox_square-aero.checked.disabled {
  background-position: -96px 0;
}

.iradio_square-aero {
  background-position: -120px 0;
}
.iradio_square-aero.hover {
  background-position: -144px 0;
}
.iradio_square-aero.checked {
  background-position: -168px 0;
}
.iradio_square-aero.disabled {
  background-position: -192px 0;
  cursor: default;
}
.iradio_square-aero.checked.disabled {
  background-position: -216px 0;
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_square-aero,
  .iradio_square-aero {
    background-image: url(aero@2x.png);
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px;
  }
}

/* grey */
.icheckbox_square-grey,
.iradio_square-grey {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(/build/images/iCheck/square/grey.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square-grey {
  background-position: 0 0;
}
.icheckbox_square-grey.hover {
  background-position: -24px 0;
}
.icheckbox_square-grey.checked {
  background-position: -48px 0;
}
.icheckbox_square-grey.disabled {
  background-position: -72px 0;
  cursor: default;
}
.icheckbox_square-grey.checked.disabled {
  background-position: -96px 0;
}

.iradio_square-grey {
  background-position: -120px 0;
}
.iradio_square-grey.hover {
  background-position: -144px 0;
}
.iradio_square-grey.checked {
  background-position: -168px 0;
}
.iradio_square-grey.disabled {
  background-position: -192px 0;
  cursor: default;
}
.iradio_square-grey.checked.disabled {
  background-position: -216px 0;
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_square-grey,
  .iradio_square-grey {
    background-image: url(grey@2x.png);
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px;
  }
}

/* orange */
.icheckbox_square-orange,
.iradio_square-orange {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(/build/images/iCheck/square/orange.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square-orange {
  background-position: 0 0;
}
.icheckbox_square-orange.hover {
  background-position: -24px 0;
}
.icheckbox_square-orange.checked {
  background-position: -48px 0;
}
.icheckbox_square-orange.disabled {
  background-position: -72px 0;
  cursor: default;
}
.icheckbox_square-orange.checked.disabled {
  background-position: -96px 0;
}

.iradio_square-orange {
  background-position: -120px 0;
}
.iradio_square-orange.hover {
  background-position: -144px 0;
}
.iradio_square-orange.checked {
  background-position: -168px 0;
}
.iradio_square-orange.disabled {
  background-position: -192px 0;
  cursor: default;
}
.iradio_square-orange.checked.disabled {
  background-position: -216px 0;
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_square-orange,
  .iradio_square-orange {
    background-image: url(orange@2x.png);
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px;
  }
}

/* yellow */
.icheckbox_square-yellow,
.iradio_square-yellow {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(/build/images/iCheck/square/yellow.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square-yellow {
  background-position: 0 0;
}
.icheckbox_square-yellow.hover {
  background-position: -24px 0;
}
.icheckbox_square-yellow.checked {
  background-position: -48px 0;
}
.icheckbox_square-yellow.disabled {
  background-position: -72px 0;
  cursor: default;
}
.icheckbox_square-yellow.checked.disabled {
  background-position: -96px 0;
}

.iradio_square-yellow {
  background-position: -120px 0;
}
.iradio_square-yellow.hover {
  background-position: -144px 0;
}
.iradio_square-yellow.checked {
  background-position: -168px 0;
}
.iradio_square-yellow.disabled {
  background-position: -192px 0;
  cursor: default;
}
.iradio_square-yellow.checked.disabled {
  background-position: -216px 0;
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_square-yellow,
  .iradio_square-yellow {
    background-image: url(yellow@2x.png);
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px;
  }
}

/* pink */
.icheckbox_square-pink,
.iradio_square-pink {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(/build/images/iCheck/square/pink.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square-pink {
  background-position: 0 0;
}
.icheckbox_square-pink.hover {
  background-position: -24px 0;
}
.icheckbox_square-pink.checked {
  background-position: -48px 0;
}
.icheckbox_square-pink.disabled {
  background-position: -72px 0;
  cursor: default;
}
.icheckbox_square-pink.checked.disabled {
  background-position: -96px 0;
}

.iradio_square-pink {
  background-position: -120px 0;
}
.iradio_square-pink.hover {
  background-position: -144px 0;
}
.iradio_square-pink.checked {
  background-position: -168px 0;
}
.iradio_square-pink.disabled {
  background-position: -192px 0;
  cursor: default;
}
.iradio_square-pink.checked.disabled {
  background-position: -216px 0;
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_square-pink,
  .iradio_square-pink {
    background-image: url(pink@2x.png);
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px;
  }
}

/* purple */
.icheckbox_square-purple,
.iradio_square-purple {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(/build/images/iCheck/square/purple.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square-purple {
  background-position: 0 0;
}
.icheckbox_square-purple.hover {
  background-position: -24px 0;
}
.icheckbox_square-purple.checked {
  background-position: -48px 0;
}
.icheckbox_square-purple.disabled {
  background-position: -72px 0;
  cursor: default;
}
.icheckbox_square-purple.checked.disabled {
  background-position: -96px 0;
}

.iradio_square-purple {
  background-position: -120px 0;
}
.iradio_square-purple.hover {
  background-position: -144px 0;
}
.iradio_square-purple.checked {
  background-position: -168px 0;
}
.iradio_square-purple.disabled {
  background-position: -192px 0;
  cursor: default;
}
.iradio_square-purple.checked.disabled {
  background-position: -216px 0;
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_square-purple,
  .iradio_square-purple {
    background-image: url(purple@2x.png);
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px;
  }
}
