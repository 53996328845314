/* Lisätään reunat
----------------------------------- */

//valittu kuva (kuva korostuu)
.selected{
    border:solid 10px #ff7a00;
    opacity: 1;
    margin:10px;
}

//kuvavaihtoehdot (eivät ole korostettuja)
.answer-picture{
    border:10px solid transparent;
    opacity: .5;
    margin:10px;
}

//staattisen kuvan läpinäkyvä raja (ei korostunut)
.static-answer-picture {
    border:10px solid transparent;
    margin:10px;
}

//nappien korostukset
.highlight-buttons {
    text-shadow: 0 0 15px #ff7a00;
}

//korjaus applen tuotteille, että highlight näkyy (muuten ei näy text-shadow)
.btn-link.highlight-buttons {
    border-radius: 0;
}

//korostettu nappi, joka osoittaa play-nappia jos sitä tarvitsee painaa
#push-this {
    position: relative;
    color: #ff7a00;
}
