// vastaussivun nappien ja kuvien container
.responsive-container {
    position: relative;
    width: 100%;
    height: 100%;
}

//vastauskuvat sisältävä taulu
.images {
    max-width:100%;
    max-height:90%;
    height: 90%;
    width: 100%;
}

//taulun kolumni
.images td {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

//vastaussivun kuvat
.imageholder img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}
