// Suurentaa fontawesome ikoneita sekä radio/check-nappeja
// -----------------------------------

/* Responsiivisuus */
/* Large desktops and laptops */
@media (min-width: 1200px) {
    .play-sound { font-size: 1.5em; }
    .stop-sound { font-size: 1.5em; }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    .play-sound { font-size: 1.5em; }
    .stop-sound { font-size: 1.5em; }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
    .play-sound { font-size: 2em; }
    .stop-sound { font-size: 2em; }
    .fa-trash { font-size: 1.5em; }
    .fa-edit { font-size: 1.5em; }
    .fa-copy { font-size: 1.5em; }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
    .play-sound { font-size: 2em; }
    .stop-sound { font-size: 2em; }
    .fa-trash { font-size: 1.5em; }
    .fa-edit { font-size: 1.5em; }
    .fa-copy { font-size: 1.5em; }
    input[type=radio], input[type=checkbox]
    {
        width:20px;
        height:20px;
    }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
    .play-sound { font-size: 2.5em; }
    .stop-sound { font-size: 2.5em; }
    .fa-trash { font-size: 1.5em; }
    .fa-copy { font-size: 1.5em; }
    input[type=radio], input[type=checkbox]
    {
        width:20px;
        height:20px;
    }
}
